import { ModuleWithProviders, NgModule } from "@angular/core";
import { CustomMailChipsComponent } from "./components/custom-mail-chips/custom-mail-chips.component";
import { MultipleFileUploadComponent } from "./components/multiple-file-upload/multiple-file-upload.component";


@NgModule({
    imports: [
        CustomMailChipsComponent,
        MultipleFileUploadComponent,
    ],
    exports: [
        MultipleFileUploadComponent,
        CustomMailChipsComponent,
    ],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [

            ]
        };
    }

    static forChild(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: []
        };
    }
}
