import { HttpEventType } from "@angular/common/http";
import { EventEmitter } from "@angular/core";
import { Component, Input, ViewChild, OnChanges, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, Output } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { BaseError, GenericServiceResponse, NTSTranslatePipe, TextButtonComponent } from "@nts/std";
import { firstValueFrom, forkJoin, lastValueFrom, Observable, tap } from "rxjs";
import { SvgIconComponent } from "@ngneat/svg-icon"
import { NgFor, NgIf } from "@angular/common";
import { ProgressComponent } from "./progress/progress.component";
import { DndDirective } from "./dnd/dnd.directive";

export interface FileUploadInterface {
    fileUploadOriginalFileName: string;
    fileUploadStorageName?: string;
    fileUploadFileSize?: number;
    fileObject?: File
    fileData?: any
    filePreviewUrl: SafeResourceUrl;
    fileErrors: BaseError[]
}
export interface FileUploadStatusInterface extends FileUploadInterface {
    progress?: number;
}

export interface ColorArrayAwareInterface<T> extends Array<T> {
    defaultColor?: string;
    activeColor?: string;
    hoverColor?: string
}

@Component({
    selector: 'nts-multiple-file-upload',
    templateUrl: './multiple-file-upload.component.html',
    styleUrls: ['./multiple-file-upload.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        SvgIconComponent,
        NgIf,
        TextButtonComponent,
        ProgressComponent,
        NgFor,
        NTSTranslatePipe,
        DndDirective
    ]
})
export class MultipleFileUploadComponent implements OnChanges, OnInit {

    @Input() editable = true;
    @Input() showUploadContainer = true;
    @Input() showActions = true;
    @Input() showProgress = true;
    @Input() iconSize = '50px';
    @Input() files: ColorArrayAwareInterface<FileUploadStatusInterface> = []; // In ingresso passo i file già caricati
    @Input() isRemoteUploadEnabled = false
    @Input() moveUpCallBack: (itemIndex: number) => Promise<void> = null;
    @Input() moveDownCallBack: (itemIndex: number) => Promise<void> = null;
    //TODO DTO
    @Input() uploadCallBack: (item: FileUploadStatusInterface, file: File) => Observable<GenericServiceResponse<any>> = null;
    @Input() viewFileCallBack: (item: FileUploadStatusInterface) => Observable<any> = null;
    @Input() addCallBack: () => Promise<FileUploadStatusInterface> = null;
    @Input() removeCallBack: (itemIndex: number) => Promise<void> = null;


    @Output() filesChanged: EventEmitter<void> = new EventEmitter<void>()
    @ViewChild('fileDropRef') fileDropRef;

    isHover = false;
    isActive = false;
    overrideBorderColor = null;

    constructor(private sanitizer: DomSanitizer, private readonly cd: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.handleOverridesColors();
    }

    mouseout(e) {
        this.isHover = false;
        this.handleOverridesColors();
    }

    onBlur(e) {
        this.isActive = false;
        this.handleOverridesColors();
    }

    onFocus(e) {
        this.isActive = true;
        this.handleOverridesColors();
    }

    mouseover(e) {
        this.isHover = true;
        this.handleOverridesColors();
    }

    handleOverridesColors() {
        if (!this.files.defaultColor || !this.files.activeColor || !this.files.hoverColor) {
            // devono essere impostate tutte e tre le variabili
            return;
        }

        this.overrideBorderColor = this.files.defaultColor;
        if (this.isActive) {
            this.overrideBorderColor = this.files.activeColor;
        }
        if (this.isHover) {
            this.overrideBorderColor = this.files.hoverColor;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Imposto il progress 100% nei file fià caricati
        if (changes['files']?.currentValue) {
            const arr: FileUploadStatusInterface[] = changes['files']?.currentValue;
            for (const singleFile of arr) {
                singleFile.progress = 100;
                // if (singleFile?.fileObject?.type?.startsWith('image')) {
                //     const previewReader: FileReader = new FileReader();
                //     previewReader.onloadend = (e) => {
                //         singleFile.filePreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(e.target.result as string);
                //         this.cd.markForCheck();
                //     }
                //     previewReader.readAsDataURL(singleFile.fileObject);
                // }
            }
        }
    }

    onFileDropped(e: FileList) {
        if (this.editable) {
            this.uploadFiles(e);
        }
    }

    async uploadFile(item: FileUploadStatusInterface, file: File) {
        this.filesChanged.emit()
        return lastValueFrom(
            this.uploadCallBack(item, file).pipe(
                tap((httpEvent: any) => {
                    if (httpEvent?.type === HttpEventType.UploadProgress) {
                        item.progress = (Math.round(100 * httpEvent.loaded / httpEvent.total) * 0.75);
                        this.cd.markForCheck();
                    } else if (httpEvent instanceof GenericServiceResponse) {
                        item.progress = 100;
                        item.fileUploadStorageName = httpEvent.result?.storageFileName;
                        item.fileErrors = httpEvent.errors

                        // if (item?.fileObject?.type?.startsWith('image')) {
                        //     const previewReader: FileReader = new FileReader();
                        //     previewReader.onloadend = (e) => {
                        //         item.filePreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(e.target.result as string);
                        //         this.cd.markForCheck();
                        //     }
                        //     previewReader.readAsDataURL(item.fileObject);
                        // }
                        this.filesChanged.emit()
                        this.cd.markForCheck();
                    } else if (httpEvent?.type === HttpEventType.Sent) {
                        item.progress = 1;
                        this.filesChanged.emit()
                        this.cd.markForCheck();
                    }
                })
            )
        )
    }

    async uploadFiles(files: FileList) {

        if (this.isRemoteUploadEnabled) {
            const tasks$ = Array.from(files).map(async (file) => {
                const item = await this.addCallBack();
                item.fileObject = file;
                item.fileUploadFileSize = file.size;
                item.fileUploadOriginalFileName = item.fileObject.name;
                return this.uploadFile(item, file)
            });
            firstValueFrom(forkJoin(tasks$));
        } else {

            for (const singleFile of Array.from(files)) {
                const item = await this.addCallBack();
                item.fileObject = singleFile;
                item.fileUploadFileSize = item.fileObject.size;
                item.fileUploadOriginalFileName = item.fileObject.name;
                this.cd.markForCheck();
                await this.simulateUpload(item, false);
            }

        }

        this.cd.detectChanges();
    }

    simulateUpload(item: FileUploadStatusInterface, instant: boolean): Promise<void> {
        return new Promise((resolve, reject) => {

            item.progress = 0;
            setTimeout(() => {
                const progressInterval = setInterval(() => {
                    if (item.progress === 100) {
                        clearInterval(progressInterval);

                        // if (item?.fileObject?.type?.startsWith('image')) {
                        //     const previewReader: FileReader = new FileReader();
                        //     previewReader.onloadend = (e) => {
                        //         item.filePreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(e.target.result as string);
                        //         this.cd.markForCheck();
                        //     }
                        //     previewReader.readAsDataURL(item.fileObject);
                        // }

                        resolve();

                    } else {
                        item.progress += 5;
                    }
                    this.cd.markForCheck();
                }, instant ? 0 : 20);
                this.cd.markForCheck();
            }, instant ? 0 : 500);
        });
    }

    /**
     * handle file from browsing
     */
    fileBrowseHandler(e) {
        this.uploadFiles(e.target.files);
    }

    viewFile(e) {
        this.viewFileCallBack(e)
    }

    async retryUpload(file: FileUploadStatusInterface) {
        file.fileErrors = []
        await this.uploadFile(file, file.fileObject)
    }

    getKeyFromFile(item: FileUploadStatusInterface) {
        if (item?.fileUploadOriginalFileName?.toLowerCase().endsWith('avi')) {
            return 'avi-format'
        }
        if (item?.fileUploadOriginalFileName?.toLowerCase().endsWith('gif')) {
            return 'gif-format'
        }
        if (item?.fileUploadOriginalFileName?.toLowerCase().endsWith('jpeg') ||
            item?.fileUploadOriginalFileName?.toLowerCase().endsWith('jpg')) {
            return 'jpeg-format'
        }
        if (item?.fileUploadOriginalFileName?.toLowerCase().endsWith('svg')) {
            return 'svg-format'
        }
        if (item?.fileUploadOriginalFileName?.toLowerCase().endsWith('zip')) {
            return 'compress'
        }
        if (item?.fileUploadOriginalFileName?.toLowerCase().endsWith('mpeg')) {
            return 'mpeg-format'
        }
        if (item?.fileUploadOriginalFileName?.toLowerCase().endsWith('png')) {
            return 'png-format'
        }
        if (item?.fileUploadOriginalFileName?.toLowerCase().endsWith('raw')) {
            return 'raw-format'
        }
        if (item?.fileUploadOriginalFileName?.toLowerCase().endsWith('tif')) {
            return 'tif-format'
        }
        if (item?.fileUploadOriginalFileName?.toLowerCase().endsWith('tiff')) {
            return 'tiff-format'
        }
        if (item?.fileUploadOriginalFileName?.toLowerCase().endsWith('webp')) {
            return 'webp-format'
        }
        if (item?.fileUploadOriginalFileName?.toLowerCase().endsWith('mkv')) {
            return 'media-video'
        }
        return 'journal';
    }

    async moveUpFile(fileIndex: number) {
        await this.moveUpCallBack(fileIndex);
    }

    async moveDownFile(fileIndex: number) {
        await this.moveDownCallBack(fileIndex);
    }

    formatBytes(bytes: number, decimals = 2) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals || 2;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    /**
     * Delete file from files list
     * @param index (File index)
     */
    async deleteFile(index: number) {
        await this.removeCallBack(index);
        this.fileDropRef.nativeElement.value = '';
        // this.onFilesChanged.emit(this.files);
        this.filesChanged.emit()
    }

}
