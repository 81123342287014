import { AfterViewInit, ChangeDetectorRef, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";
import { LabelBoxComponent, StringPropertyViewModel } from "@nts/std";
import { Chips } from 'primeng/chips';
import { NgxPopperjsPlacements, NgxPopperjsTriggers, NgxPopperjsModule } from "ngx-popperjs";
import { merge, Subject, takeUntil } from "rxjs";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ChipsModule } from 'primeng/chips';
import { NgFor, NgIf } from "@angular/common";
import { FormsModule } from "@angular/forms";

@UntilDestroy()
@Component({
    selector: 'nts-custom-mail-chips',
    templateUrl: './custom-mail-chips.component.html',
    styleUrls: ['./custom-mail-chips.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ChipsModule,
        NgxPopperjsModule,
        LabelBoxComponent,
        NgFor,
        NgIf,
        FormsModule
    ]
})
export class CustomMailChipsComponent implements AfterViewInit, OnChanges {

    @HostBinding('class.full-column')
    @Input() fullColumn = false;
    @Input() showLabel = true;
    @Input() propertyViewModel: StringPropertyViewModel;

    @ViewChild('customChips') editor: Chips;

    arrayModel: string[] = [];
    ngxPopperjsTriggers = NgxPopperjsTriggers;
    ngxPopperjsPlacements = NgxPopperjsPlacements;

    constructor(protected readonly cd: ChangeDetectorRef) { }

    protected destroyOnChange$: Subject<boolean> = new Subject<boolean>();

    ngAfterViewInit(): void {
        //dopo il view init, se la proprietà non è nulla provo a popolare l'array usato dal componente chips
        if (this.notNullOrUndefined(this.propertyViewModel)) {
            this.refreshArrayModel(this.propertyViewModel);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['propertyViewModel']) {
            //al cambio property view model, aggiorno l'array interno con le mail e se necessario allineo il propertyViewModel
            this.destroyOnChange$.next(true);
            this.refreshArrayModel(changes['propertyViewModel'].currentValue);
            merge(
                this.propertyViewModel.customGetterValueChanged,
                this.propertyViewModel.propertyViewModelChanged,
                this.propertyViewModel.onErrorStatusChanged,
            ).pipe(
                untilDestroyed(this),
                takeUntil(this.destroyOnChange$)
            ).subscribe((x) => {
                this.cd.detectChanges();
            });

            this.propertyViewModel.propertyChanged.pipe(
                untilDestroyed(this),
                takeUntil(this.destroyOnChange$)
            ).subscribe((arg) => {
                this.cd.detectChanges();
            });
        }
    }


    private refreshArrayModel(propertyViewModel: StringPropertyViewModel) {
        //se il valore del property passato non è nullo provo a popolare l'array model usato dal chips
        if (this.notNullOrUndefined(propertyViewModel) && this.notNullOrUndefined(propertyViewModel.value)) {
            //se il property trimmato è più lungo di 0 vuol dire che non è stringa vuota; quindi splitto sul ; che è la convenzione del
            // portale divisione delle mail
            //TODO si potrebbe customizzare il separatore?
            if (propertyViewModel.value.trim().length > 0) {
                this.arrayModel = propertyViewModel.value.split(";").map(x => x)
            }
            //se invece è 0 vuol dire che l'array model deve svuotarsi.
            else {
                this.arrayModel = [];
            }
        } else {
            this.arrayModel = [];
        }
    }

    updatePropertyViewModel() {
        var joinedMailList = this.arrayModel.join(";");
        if (joinedMailList.length <= this.propertyViewModel.metadataMaxLength) {
            this.propertyViewModel.value = joinedMailList;
            this.updateMaxLength();
        } else {
            //volendo qua si può aggiungere un messaggio di errore?
            //questo è utilizzato per gestire il caso del ctrl + v
            this.arrayModel.pop();
        }

    }

    private notNullOrUndefined = value => value != null && value != undefined


    private updateMaxLength() {
        //aggiorno il numero di caratteri massimi dell'input interno al componente chips:
        //prima faccio la differenza tra il massimo che arriva dai metadati e la lunghezza del property view model
        var newMaxLength = this.propertyViewModel.metadataMaxLength - this.propertyViewModel.value.length;
        //poi se la lunghezza massima è maggiore di 0, sottraggo 1 per gestire il fatto che ad ogni nuovo valore inserito in input
        //viene aggiunto un ';' come separatore, quindi gli input devono lasciare almeno uno spazio per aggiungere un carattere
        newMaxLength = newMaxLength > 0 ? newMaxLength - 1 : 0;
        this.editor.el.nativeElement.querySelector('.p-chips-input-token input').setAttribute('maxLength', newMaxLength);
    }
}
