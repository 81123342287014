import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { BaseRoutes } from '@nts/std';
import { environment } from 'src/environments/environment';
const title = environment ? environment.appTitle : 'Application Title';

const routes: Routes = [

  // #region rotte con autenticazione
  ...BaseRoutes.getBaseAuthentictedRoutes(
    environment,
    [
      ...BaseRoutes.getEntityRoutes(
        title,
        "accountconfiguration",
        () => import('./account-configuration/account-configuration.module').then(m => m.AccountConfigurationModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "commonaccountconfiguration",
        () => import('./common-account-configuration/common-account-configuration.module').then(m => m.CommonAccountConfigurationModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'agenda',
        () => import('./agenda/agenda.module').then(m => m.AgendaModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'sharingmanager',
        () => import('./sharing-manager/sharing-manager.module').then(m => m.SharingManagerModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'fileexplorer',
        () => import('./file-explorer/file-explorer.module').then(m => m.FileExplorerModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'addressbook',
        () => import('./address-book/address-book.module').then(m => m.AddressBookModule)
      ),
      ...BaseRoutes.getDefaultRoutes(
        title,
        environment,
        true
      )
    ]
  ),
  // #endregion rotte con autenticazione

  // rotte base per la gestione degli errori
  ...BaseRoutes.getBaseErrorsRoute()
];

@NgModule({
  imports: [
    // TODO Tommy: si potrebbe creare una custom preloadin strategy per precaricare solo le rotte offline
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', paramsInheritanceStrategy: 'always', preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
